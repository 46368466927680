//
// webphone.scss
//
.webphone-container {
  position: absolute !important;
  top: 50%;
  left: 50%;
  .webphone-content {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin: 0 auto;
    width: 500px;
    height: 500px;
    border-radius: 50px;
    background: red;
  }
}
