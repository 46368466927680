// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.18) !important;
    }
}

.bg-soft-dark{
    background-color: rgba(var(--#{$variable-prefix}dark-rgb),.18)!important;
}

.bg-soft-light{
    background-color: rgba(var(--#{$variable-prefix}light-rgb),.18)!important;
}

