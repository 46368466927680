// 
// tour.scss
// 

.shepherd-element{
    background: $card-bg;
    box-shadow: $box-shadow;
}

.shepherd-has-title{
    .shepherd-content{
        .shepherd-header{
            background-color: var(--#{$variable-prefix}light);
            padding: $toast-padding-y $toast-padding-x;
        }

        .shepherd-cancel-icon{
            color: rgba(var(--#{$variable-prefix}dark-rgb), .75);
            &:hover{
                color: rgba(var(--#{$variable-prefix}dark-rgb), .75);
            }
        }
    }
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before{
    background-color: var(--#{$variable-prefix}light);
}

.shepherd-title{
    font-size: 15px;
    font-weight: $font-weight-medium;
    color: var(--#{$variable-prefix}body-color);
}

.shepherd-text{
    padding: $toast-padding-x;
    font-size: $font-size-base;
    color: var(--#{$variable-prefix}body-color);
}

.shepherd-button{
    &.btn-success:not(:disabled):hover{
        background: darken($success, 2.5%);
        color: $white;
    }

    &.btn-light:not(:disabled):hover{
        background: rgba(var(--#{$variable-prefix}light-rgb), .75);
        color: var(--#{$variable-prefix}dark);
    }

    &.btn-primary:not(:disabled):hover{
        background: darken($primary, 2.5%);
        color: $white;
    }
}

.shepherd-footer{
    padding: 0 $toast-padding-x $toast-padding-x;
}
.shepherd-arrow,
.shepherd-arrow:before{
    content: "\ea75";
    font-family: 'remixicon';
    font-size: 24px;
    z-index: 1;
    background-color: transparent !important;
    transform: rotate(0deg);
    color: $primary;
}

.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow{
    top: -18px;
}

.shepherd-button{
    margin-right: 0.5rem;
}