//
// _messageBox.scss
//

.message-box {
  max-width: 40%;
  padding: 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
  margin-bottom: 10px;
  word-wrap: break-word;
  position: relative;

  .message-content {
    margin: 0;
  }

  .messege-author {
    padding: 0;
    margin: 0;
    color: #666;
    font-size: 12px;
  }

  .message-time {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 8px;
    color: #666;
  }
}

.message-employee {
  margin-left: auto;
  background-color: #e0f7fa;
}

.message-client {
  margin-right: auto;
  background-color: #e0f7fa;
}
